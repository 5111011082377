import settings from '@/settings'

/**
 * 異常告警
 */
class Warning {
  /**
   * 學校異常告警歷程
   */
  school(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/ems/warning/school/${schoolId}`,
      { params: params }
    )
  }

  /**
   * 班級異常告警歷程
   */
  class(classId, params) {
    return axios.get(settings.api.fullPath + `/ems/warning/class/${classId}`, {
      params: params
    })
  }
}

var warning = new Warning()
export default warning
