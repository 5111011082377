<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="readonly"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        :prepend-icon="prependIcon"
        readonly
        v-bind="attrs"
        v-on="on"
        class="date-picker-input"
        :solo="solo"
        :hide-details="hideDetails"
        :clearable="clearable"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      no-title
      scrollable
      locale="zh-TW"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'InputMonthPicker',
  mixins: [],
  components: {},
  props: {
    value: {},
    label: {
      type: String,
      default: '請選擇日期'
    },
    prependIcon: {
      default: 'mdi-calendar'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean || String
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      menu: null,
      date: null
    }
  },
  computed: {},
  watch: {
    value: function (val) {
      this.date = val
    },
    date: function (val) {
      this.commit()
      this.onChange()
    }
  },
  created: function () {},
  mounted: function () {
    this.date = this.value || null
  },
  destroyed() {},
  methods: {
    commit() {
      this.$emit('input', this.date)
    },
    onChange() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped></style>
