<template>
  <WarningListSearch :tagId="tagId" :searchFunction="searchFunction" />
</template>

<script>
import WarningListSearch from '@/components/WarningListSearch'
import Warning from '@/api/ems/Warning'

export default {
  components: {
    WarningListSearch
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchFunction: Warning.school
    }
  }
}
</script>
