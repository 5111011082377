<template>
  <div>
    <div class="pa-3">
      <v-card
        class="mx-auto pa-3 ems-opacity-50-bg frosted-glass"
        elevation="0"
      >
        <v-row>
          <v-col cols="12" sm="6" md="8">
            <div class="accent--text text-h6 px-2">異常警告清單</div>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <div class="d-flex">
              <InputMonthPicker
                label="月份"
                solo
                hide-details
                prepend-icon=""
                v-model="warning.query.month"
                clearable
              />
              <v-btn
                class="ml-4 px-8 accent--text"
                color="ems-aquamarine"
                large
                @click="getInfo"
              >
                查詢
              </v-btn>
            </div>
            <v-select
              v-if="warning.data.length"
              v-model="warning.query.emshmialerttype"
              :items="emshmialerttypes"
              item-text="name"
              item-value="value"
              label="通知類型"
              class="mt-4"
              solo
              hide-details
              clearable
              prepend-inner-icon="mdi-filter"
            ></v-select>
          </v-col>
        </v-row>
        <div class="mt-4">
          <v-data-table
            :headers="warning.headers"
            :items="filterWarningData"
            :items-per-page="warning.perPage"
            :loading="warning.loading"
            loading-text="Loading..."
            no-data-text="查無資料"
            :sort-by="['timestamp']"
            :sort-desc="[true]"
            :footer-props="{
              'items-per-page-text': '每頁'
            }"
            class="elevation-0"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.timestamp`]="{ item }">
              <div>
                {{
                  item.timestamp
                    ? moment(item.timestamp).format('YYYY-MM-DD HH:mm')
                    : null
                }}
              </div>
            </template>
            <template v-slot:[`item.emshmialerttype`]="{ item }">
              <div>
                {{ getType(item.emshmialerttype) }}
              </div>
            </template>
            <template v-slot:[`item.emshmialertcause`]="{ item }">
              <div>
                {{ getCause(item.emshmialerttype, item.emshmialertcause) }}
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputMonthPicker from '@/components/InputMonthPicker'

export default {
  components: {
    InputMonthPicker
  },
  props: {
    tagId: {
      type: String,
      required: true
    },
    searchFunction: {
      type: Function,
      default: () => {
        return
      }
    }
  },
  data() {
    return {
      warning: {
        headers: [
          {
            text: '時間',
            value: 'timestamp',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: '類型',
            value: 'emshmialerttype',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: '通知原因',
            value: 'emshmialertcause',
            class: 'blue lighten-4 text-subtitle-2'
          },
          {
            text: '描述',
            value: 'emshmialertcontent',
            class: 'blue lighten-4 text-subtitle-2'
          }
        ],
        loading: false,
        data: [],
        perPage: 10,
        currentPage: 1,
        query: {
          month: null,
          emshmialerttype: null
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      emshmialerttype: 'warning/types',
      emshmialertcause: 'warning/causes'
    }),
    emshmialerttypes() {
      if (this.$route.name === 'ClassWarning')
        return this.emshmialerttype.filter(
          (type) => type.cause === 'type-2' || type.cause === 'type-4'
        )
      else
        return this.emshmialerttype.filter(
          (type) => type.cause !== 'type-2' && type.cause !== 'type-4'
        )
    },
    filterWarningData() {
      let data = this.warning.data || []
      if (this.warning.query.emshmialerttype) {
        data = data.filter((item) => {
          return this.warning.query.emshmialerttype === item.emshmialerttype
        })
      }
      return data
    }
  },
  mounted() {
    this.warning.query.month = moment().format('YYYY-MM')
    this.getInfo()
  },
  methods: {
    async getInfo() {
      this.warning.loading = true
      try {
        const params = {
          from: moment(this.warning.query.month, 'YYYY-MM')
            .startOf('month')
            .format('YYYY-MM-DD'),
          to: moment(this.warning.query.month, 'YYYY-MM')
            .endOf('month')
            .format('YYYY-MM-DD')
        }
        const { data } = await this.searchFunction(this.tagId, params)
        this.warning.data = data || []
      } catch (error) {
        console.error(error)
      } finally {
        this.warning.loading = false
      }
    },
    getType(item) {
      if (!item) return '--'
      const type = this.emshmialerttype.find(({ value }) => value === item)
      return type ? type.name : '--'
    },
    getCause(type, item) {
      const keyObj = this.emshmialerttype.find(({ value }) => value === type)
      if (!keyObj) return '--'
      const key = keyObj.cause || ''
      const cause = this.emshmialertcause[key].find(
        ({ value }) => value === item
      )
      return cause ? cause.name : '--'
    }
  }
}
</script>

<style></style>
